<template>
  <div id="logged-out-admin-layout">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'LoggedOutAdminLayout',
};
</script>

<style lang="scss" scoped>
</style>
