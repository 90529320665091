{
  "admin.loginScreen.form.title": "Admin bejelentkezés",
  "admin.loginScreen.form.email.placeholder": "E-mail",
  "admin.loginScreen.form.password.placeholder": "Password",
  "admin.loginScreen.form.submitButton.title": "Bejelentkezés",
  "admin.layout.navbar.userList": "Felhasználók",
  "admin.layout.navbar.campaignList": "Kampányok",
  "admin.layout.navbar.organisation": "Szervezet",
  "admin.layout.navbar.logout": "Kijelentkezés",
  "admin.users.list.header.id": "Felhasználó azonosító",
  "admin.users.list.header.firstName": "Keresztnév",
  "admin.users.list.header.lastName": "Vezetéknév",
  "admin.users.list.header.status": "Státusz",
  "userListScreen.search.placeholder": "Kezdjen el gépelni a kereséshez...",
  "campaignListScreen.search.placeholder": "Kezdjen el gépelni a kereséshez...",
  "admin.campaigns.list.header.id": "Kampány azonosító",
  "admin.campaigns.list.header.title": "Cím",
  "admin.campaigns.list.header.description": "Leírás",
  "admin.campaigns.list.header.status": "Státusz",
  "admin.users.list.status.active": "AKTÍV",
  "admin.users.list.status.inactive": "INAKTÍV",
  "admin.campaigns.list.status.draft": "PISZKOZAT",
  "admin.campaigns.list.status.active": "AKTÍV",
  "admin.campaigns.list.status.paused": "MEGÁLLÍTOTT",
  "admin.campaigns.list.status.completed": "BEFEJEZETT",
  "admin.campaigns.list.status.failed": "MEGHIUSÚLT",
  "admin.userProfileScreen.header._id": "Azonosító:",
  "admin.userProfileScreen.header.email": "E-mail:",
  "admin.userProfileScreen.header.firstName": "Keresztnév:",
  "admin.userProfileScreen.header.lastName": "Vezetéknév:",
  "admin.userProfileScreen.header.bio": "Bio:",
  "admin.userProfileScreen.header.status": "Státusz:",
  "admin.campaignDetailsScreen.header._id": "Azonosító:",
  "admin.campaignDetailsScreen.header.title": "Cím:",
  "admin.campaignDetailsScreen.header.description": "Leírás:",
  "admin.campaignDetailsScreen.header.status": "Státusz:",
  "admin.campaignDetailsScreen.header.status.0": "PISZKOZAT",
  "admin.campaignDetailsScreen.header.status.1": "AKTÍV",
  "admin.campaignDetailsScreen.header.status.2": "MEGÁLLÍTOTT",
  "admin.campaignDetailsScreen.header.status.3": "BEFEJEZETT",
  "admin.campaignDetailsScreen.header.status.4": "MEGHIUSÚLT",
  "admin.campaignDetailsScreen.mediaList.title": "Galéria",
  "admin.campaignDetailsScreen.resources.title": "Szükségletek lista",
  "admin.campaignDetailsScreen.resources.type.0": "Tárgyi",
  "admin.campaignDetailsScreen.resources.type.1": "Önkéntesség",
  "admin.resourceDetals.fulfillments.id": "Azonosító",
  "resources.status.inactive": "Inaktív",
  "resources.status.active": "Aktív",
  "admin.resourceDetalsScreen.fulfillments.user": "Felhaszánló név",
  "admin.resourceDetalsScreen.fulfillments.lastMessage": "Utolsó üzenet",
  "admin.resourceDetalsScreen.fulfillments.status": "Státusz",
  "admin.fulfillmentDetailsScreen.form.message.placeholder": "Kezdje el írni az üzenetet...",
  "admin.campaignDetailsScreen.resources.add": "Új hozzáadása",
  "admin.campaignListScreen.newModalForm.title.label": "Kampány cím",
  "admin.campaignListScreen.newModalForm.title.placeholder": "Adja meg a címet",
  "admin.campaignListScreen.newModalForm.description.label": "Leírás",
  "admin.campaignListScreen.newModalForm.description.placeholder": "Meséljen a szervezetről...",
  "campaignListScreen.showNewModalButton.title": "Új létrehozása",
  "admin.campaignDetailsScreen.newModalForm.name.label": "Név",
  "admin.campaignDetailsScreen.newModalForm.name.placeholder": "Adjon nevet",
  "admin.campaignDetailsScreen.newModalForm.type.label.0": "Tárgyi",
  "admin.campaignDetailsScreen.newModalForm.type.label.1": "Önkéntesség",
  "admin.campaignDetailsScreen.newModalForm.quantity.label": "Mennyiség",
  "admin.campaignDetailsScreen.newModalForm.quantity.placeholder": "Mennyiség",
  "admin.general.buttons.cancel": "Mégse",
  "admin.general.buttons.submit": "Küldés",
  "admin.organisationScreen.form.name.placeholder": "A szervezet neve",
  "admin.organisationScreen.form.contact.address.placeholder": "Kapcsolattartási cím",
  "admin.organisationScreen.form.contact.phone.placeholder": "Kapcsolattartási telefonszám",
  "admin.organisationScreen.form.contact.email.placeholder": "Kapcsolattartási e-mail cím",
  "admin.organisationScreen.form.introduction.placeholder": "Mutassa be a szervezetet....",
  "admin.organisationScreen.form.colors.primary.placeholder": "Elsődleges design szín",
  "admin.organisationScreen.form.colors.secondary.placeholder": "Másodlagos design szín",
  "admin.organisationScreen.form.submitButton.title": "Frissítés",
  "public.layout.navbar.search": "Kampány kereső",
  "public.layout.navbar.campaigns": "Kampányok",
  "public.layout.navbar.aboutUs": "Rólunk",
  "public.layout.navbar.logout": "Kijelentkezés",
  "public.layout.navbar.login": "Bejelentkezés",
  "public.layout.footer.contactTitle": "Kapcsolat",
  "public.layout.footer.navigationTitle": "Navigáció",
  "public.campaignSearchScreen.header.searchButton.title": "Keresés",
  "public.campaignSearchScreen.header.searchBox.title": "Település kereső",
  "public.campaignSearchScreen.header.searchBox.input.placeholder": "Írd ide a város nevét",
  "public.campaignSearchScreen.result.title": "Aktív kampányok",
  "public.general.resources.type.0": "tárgyi",
  "public.general.resources.type.1": "önkéntesség",
  "public.campaignCard.resourceTitle": "Szükségletek",
  "public.campaignCard.nextLinkText": "Tovább...",
  "public.campaignSearchScreen.header.mainTitle": "Segíts ott, ahol jelen vagy! Legyél Te is Jótevő! ",
  "public.campaignSearchScreen.header.subTitle": "Keresd meg a szükséget, amire rádobban a szíved és tégy jót! Adományozz az alábbi kampányok valamelyikén keresztül, és biztos lehetsz benne, hogy a jószándékod valóban elér a szükségben levőkhöz!",
  "public.campaignScreen.header.fulfillmentButton.title": "Jótevő leszek!",
  "public.campaignScreen.content.resources.title": "Szükségletek",
  "public.campaignScreen.content.campaignStatus.title": "Kampány állapota",
  "public.campaignScreen.content.description.title": "Kampány leírása",
  "public.campaignScreen.content.gallery.title": "Galéria",
  "public.campaignScreen.content.participants.title": "Adakozók listája",
  "public.fulfillmentModal.title": "Adományozásra jelentkezés",
  "public.fulfillmentModal.subTitle": " Jelezd itt, ha felajánlanál valamit a szükségek betöltésére, majd hozd el az intézményünkbe munkanapokon, nyitvatartási időben. Hálásak vagyunk az adományodért! ",
  "public.fulfillmentModal.messageTitle": "Üzenet",
  "public.fulfillmentModal.submitButton.title": "JELENTKEZEK",
  "public.fulfillmentModal.cancelButton.title": "MÉGSE",
  "public.modals.login.email.label": "E-mail",
  "public.modals.login.email.placeholder": "E-mail cím",
  "public.modals.login.password.label": "Jelszó",
  "public.modals.login.password.placeholder": "Adja meg a jelszavát",
  "public.campaignSearchScreen.result.emptyResultText": "Nincs találat!",
  "admin.campaignListScreen.newModalForm.location.label": "Helyszín",
  "admin.campaignListScreen.newModalForm.location.placeholder": "Merre lesz a gyűjtés?",
  "admin.campaignListScreen.newModalForm.deadline.label": "Határidő",
  "admin.campaignDetailsScreen.buttons.setStatus.1": "Kampány indítása",
  "admin.campaignDetailsScreen.buttons.setStatus.2": "Kampány szüneteltetése",
  "admin.campaignDetailsScreen.buttons.setStatus.3": "Kampány befejezése",
  "admin.campaignDetailsScreen.buttons.setStatus.4": "Kampány meghiusítása",
  "messagesModal.fulfillments.title": "Jelentkezések",
  "messagesModal.fulfillments.messageCounter": "üzenet",
  "messagesModal.messages.sendButton.title": "ÜZENET KÜLDÉSE",
  "public.modals.registration.firstName.label": "Keresztnév",
  "public.modals.registration.firstName.placeholder": "Add meg a keresztneved",
  "public.modals.registration.lastName.label": "Vezetéknév",
  "public.modals.registration.lastName.placeholder": "Add meg a vezetékneved",
  "public.modals.registration.email.label": "E-mail",
  "public.modals.registration.email.placeholder": "Milyen e-mail címen érünk el?",
  "public.modals.registration.password.label": "Jelszó",
  "public.modals.registration.password.placeholder": "Bejelentkezéshez használt jelszó",
  "admin.fulfillmentDetailsScreen.form.message.submitButtonText": "Küldés",
  "messagesModal.emptyMessages": "Nincs üzenete!",
  "public.fulfillmentModal.message.placeholder": "Legalább 13 karaktert írj! Pl.: Mikor és hol tudod átadni az adományodat?",
  "public.fulfillmentModal.resourceTitle": "Teljesítés tárgya",
  "public.fulfillmentModal.quantityTitle": "Mennyiség",
  "public.modals.registration.generalError": "A megadott adatok hibásak, kérjük ellenőrizd az adatokat",
  "public.modals.login.generalError": "A megadott adatok hibásak, kérjük ellenőrizd az adatokat"
}