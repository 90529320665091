<template>
  <div class="form-builder">
    <div v-for="(field, index) of fields" :key="index" class="form-group">
      <label>{{ field.label }}</label>
      <input
        v-if="isInput(field.type)"
        class="form-control"
        :type="field.type || 'text'"
        :placeholder="field.placeholder"
        v-model="fieldValues[field.name]"
      />
      <div
        class="form-check"
        v-for="(option, jindex) of field.options"
        :key="jindex"
      >
        <input
          class="form-check-input"
          type="radio"
          :id="`radio-${field.name}-${jindex}`"
          :value="option"
          v-model="fieldValues[field.name]"
        />
        <label class="form-check-label" :for="`radio-${field.name}-${jindex}`">
          {{ field.labels[jindex] }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <component
          v-for="(component, index) of customComponents"
          :key="index"
          :is="component.bind.is"
          v-bind="component.bind"
          v-on="component.on"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <button
          class="btn btn-primary m-3"
          @click="submit"
          v-t="submitButtonTextKey"
        ></button>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <button
          class="btn btn-danger m-3"
          @click="cancel"
          v-t="cancelButtonTextKey"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormBuilder',
  props: {
    fields: {
      type: Array,
    },
    nestedFields: {
      type: Array,
    },
    submitButtonTextKey: {
      type: String,
      default: 'admin.general.buttons.submit',
    },
    cancelButtonTextKey: {
      type: String,
      default: 'admin.general.buttons.cancel',
    },
    customComponents: {
      type: Array,
      required: false,
    },
  },
  components: {},
  data: () => ({
    fieldValues: {},
  }),
  methods: {
    submit() {
      this.$emit('submit', this.fieldValues);
    },
    cancel() {
      this.$emit('cancel');
    },
    isInput(fieldType) {
      return ['text', 'password', 'number', 'date', undefined].includes(
        fieldType //eslint-disable-line
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.form-builder {
  min-width: 30vh;
}
</style>
