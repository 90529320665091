<template>
  <ul class="navbar-nav mr-auto">
    <li
      v-for="(item, index) of navItems"
      :key="index"
      :class="classForItem(index)"
    >
      <router-link :to="item.path" class="nav-link">{{
        $t(item.translationKey)
      }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MenuList',
  props: {
    navItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    classForItem(index) {
      return {
        'nav-item': true,
        active: this.$route.fullPath === this.navItems[index].path,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-nav {
  z-index: 10;
  li {
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.68px;
    text-align: left;
    color: #3d3d3d;
    a {
      color: #3d3d3d!important;
    }
  }
}
</style>
